import React, { useState } from "react"
import classes from "./edited-by-us.module.css"
import Root from "../components/root"
import CloseButtonImage from "../gatsbyImages/CloseButtonImage"
import { navigate } from "gatsby"

import { graphql } from "gatsby"
import clsx from "clsx"

const ProjectTitle = ({ title }) => {
  return <div className={classes.projectTitle}>{title}</div>
}

const VideoCard = ({ url }) => (
  <div className={classes.imgCard}>
    <iframe
      allowFullScreen
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      src={url}
      frameBorder="0"
      width="100%"
      height="100%"
    ></iframe>
  </div>
)

function EditedByUsPage({ data }) {
  const [loadMore, setLoadMore] = useState(false)

  var arr = []

  data.allContentfulEditedByUs.nodes.map(node => {
    arr.push(node.videoUrl)
  })

  var size = loadMore ? arr.length : 4

  return (
    <Root className={classes.root}>
      <div className={classes.container}>
        <div className="flex justify-between items-center">
          <ProjectTitle title="EDITED BY US" />
          <div
            className={clsx(classes.closeBtn, "w-12 mb-20")}
            onClick={() => {
              navigate("/video-editing")
            }}
          >
            <CloseButtonImage />
          </div>
        </div>
        <div className={classes.grid}>
          {arr.slice(0, size).map((url, i) => {
            return <VideoCard url={url} />
          })}
        </div>

        {!loadMore && (
          <div onClick={e => setLoadMore(true)} className={classes.load}>
            LOAD MORE
          </div>
        )}
      </div>
    </Root>
  )
}

export const query = graphql`
  query EditedByUsQuery {
    allContentfulEditedByUs {
      nodes {
        videoUrl
      }
    }
  }
`

export default EditedByUsPage
